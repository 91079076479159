import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/auth'
const modules = '/users'
const path = base + modules

const getUserInfo = path + '/info' // 獲取個人資訊
const getUserDetails = path + '/details' // 獲取用戶詳情

const getUsersList = path // 獲取用戶列表 (含狀態)
const getUserSimpleList = path + '/simple' // 獲取用戶列表 (僅基本資訊)
const firstLogin = path + '/onboarding' // 首次登錄修改租戶名
const editUserInfo = path + '/{0}' // 修改指定用戶
const enabled = path + '/{0}/enable' // 停用/啟用定用戶
const deleteUser = path + '/{0}' // 刪除用戶

const getProfile = path + '/profile' // 獲取個人檔案
const editProfile = path + '/profile' // 修改個人檔案
const getProfileUploadUrl = getProfile + '/upload' // 獲取頭像上傳鏈接

const getTeamList = path + '/team' // 獲取用戶列表(含狀態、邀請中成員)
const searchUsers = path + '/team/q' // 搜索團隊成員

const switchDept = path + '/{0}/dept' // 新增使用者的部門(轉移部門)
const switchRole = path + '/{0}/role' // 新增使用者的角色(轉移角色)
const switchTenant = path + '/switch/tenant/{0}' // 切換租戶
const createTenant = path + '/create/tenant' // 創建租戶

// request(REQ) Function  --------------------------------

/**
 * 獲取個人資訊
 * @param {Object} callback 回調函數
 * @returns 資料(lastTenantLogin)
 */
const getUserInfoFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUserInfo, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取用戶詳情
 * @param {Object} callback 回調函數
 * @returns 資料(tenant、user、tenantAgentStatus)
 */
const getUserDetailsFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUserDetails, {
    method: 'GET',
    onResponse: callback
  })
}
/**
 * 獲取用戶列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
const getUsersListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUsersList + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取用戶列表 (可查全部僅基本資訊)
 * @param {Object} params 類型
 * @param {Object} callback 回調函數
 * @returns 資料
 */
const getUserSimpleListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getUserSimpleList, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 首次登錄修改租戶名
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const firstLoginFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + firstLogin, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改指定用戶
 * @param {String} id agentId
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const editUserInfoFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(editUserInfo, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 停用/啟用定用戶
 * @param {String} id agentId
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const enabledFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(enabled, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除用戶
 * @param {String} id agentId
 * @param {Object} callback 回調函數
 * @returns
 */
const deleteUserFn = (id, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(deleteUser, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 獲取個人檔案
 * @param {Object} callback 回調函數
 * @returns 資料
 */
const getProfileFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getProfile, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 修改個人檔案
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const editProfileFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + editProfile, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取用戶列表(含狀態、邀請中成員)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getTeamListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getTeamList + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 搜索團隊成員
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const searchUsersFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  // 對 name 進行 URL 編碼
  const encodedName = encodeURIComponent(params.name)
  return useCustomFetch(apiBaseUrl + searchUsers + '?name=' + encodedName + '&roleId=' + params.roleId + '&pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增使用者的部門(轉移部門)
 * @param {String} id agentId
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const switchDeptFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(switchDept, id)
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 新增使用者的角色(轉移角色)
 * @param {*} id agentId
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const switchRoleFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(switchRole, id)
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 租戶切換
 * @param {String} tenantId 租戶id
 * @param {Object} callback 回調函數
 * @returns
 */
const switchTenantFn = (tenantId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(switchTenant, tenantId)
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 創建新租戶
 * @param {*} formData 表單提交
 * @param {*} callback 回調函數
 * @returns
 */
const createTenantFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + createTenant, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取頭像上傳鏈接
 * @param {String} name 檔名
 * @param {Object} callback 回調函數
 * @returns
 */
const getProfileUploadUrlFn = (name, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getProfileUploadUrl + '?filename=' + name, {
    method: 'GET',
    onResponse: callback
  })
}

export {
  getUserInfoFn,
  getUserDetailsFn,
  getUsersListFn,
  firstLoginFn,
  editUserInfoFn,
  enabledFn,
  deleteUserFn,
  getProfileFn,
  editProfileFn,
  getTeamListFn,
  searchUsersFn,
  switchDeptFn,
  switchRoleFn,
  switchTenantFn,
  createTenantFn,
  getProfileUploadUrlFn,
  getUserSimpleListFn
}
